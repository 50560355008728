import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import BlockLink from './BlockLink'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  max-width: 700px;
  
  @media(min-width: 925px) {
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  display: inline-flex;
  font-family: Teko;
  text-decoration: none;
  padding: 0;
  align-items: center;
  line-height: 1.25;
  font-size: 26px;
  font-weight: normal;
  margin: 3px 0 16px;
  align-self: flex-start;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: flex-start;
  
  @media(min-width: 925px) {
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    text-decoration: none;
  }

  & + & {
    margin-top: 25px;
  }
`

const ImageLink = styled(Link)`
  margin-top: 25px;
  width: 300px;
  height: 200px;
  display: block;
  border: 2px solid #000;
  order: 2;

  @media(min-width: 925px) {
    margin-top: 0;
    margin-right: 25px;
    order: 0;
  }
`

const CoverImage = styled(Img)`
`

const Next = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  font-family: Teko;
  color: #000;
  background: transparent;
  text-decoration: none;
  padding: 0 20px;
  align-items: center;
  line-height: 1.25;
  font-size: 26px;
  font-weight: normal;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 260px;

  &::after {
    display: inline-block;
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 1.25em;
    line-height: 1;
  }
`

function WorkOverview(props) {
  return (
    <Wrapper>
      {props.works.map(work => (
        <Item key={work.title}>
          <ImageLink to={work.slug}>
            {work.coverImage && (
              <CoverImage fixed={work.coverImage.childImageSharp.fixed} alt={work.title} />
            )}
          </ImageLink>
          <BlockLink to={work.slug}>{work.title}</BlockLink>
        </Item>
      ))}
      {props.ctaUrl && props.ctaText && (
        <Next to={props.ctaUrl}>{props.ctaText}</Next>
      )}
    </Wrapper>
  );
}

WorkOverview.defaultProps = {
  works: []
};

export default WorkOverview;
