import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkOverview from "../components/WorkOverview"

const Artwork = ({ data }) => {
  const works = data.allMarkdownRemark.edges.map(edge => ({
    slug: edge.node.fields.slug,
    title: edge.node.frontmatter.title,
    coverImage: edge.node.frontmatter.coverImage
  }));

  const title = "Artwork";

  return (
    <Layout withHeader backUrl="/">
      <SEO title={title} />
      <WorkOverview title={title} works={works} />
    </Layout>
  )
}

Artwork.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Artwork

export const query = graphql`
  {
    allMarkdownRemark(
      sort: {order: [DESC, DESC], fields: [frontmatter___importance, frontmatter___date]}
      filter: {frontmatter: {templateKey: {eq: "work-detail-page"}}}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            coverImage {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 300, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`